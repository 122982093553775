<template>
  <section class="list-page">
    <!--列表-->
    <el-table :data="itemList" highlight-current-row class="list-table">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="title" label="名称"></el-table-column>
      <el-table-column prop="text" label="正文"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column
        prop="updateTime"
        :formatter="formatTime"
        label="创建时间"
      ></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="small" @click="onEditItem(scope.$index, scope.row)">
            修改
          </el-button>
          <el-button
            type="danger"
            size="small"
            @click="onDeleteItem(scope.$index, scope.row)"
            >删除</el-button
          >
          <el-link type="primary" :href="scope.row.url" target="_blank"
            >数据</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { flexQRCodeApi } from "@/api/flexQRCodeApi.js";
import { epochToDateString, epochToDay } from "@/util/TimeUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
import msg from "@/util/msg.js";
export default {
  data() {
    return {
      queryArticleName: "",
      itemList: [],
      currentPage: 1,
      totalPage: 10
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    //获取列表
    onQuery() {
      flexQRCodeApi
        .query(parseInt(this.currentPage))
        .then(ret => {
          console.log(ret);
          if (ret.code === 0) {
            this.itemList = ret.data;
            if (this.itemList) {
              const endTime = Date.now();
              const beginTime = endTime - 7 * 86400 * 1000;
              const beginDay = epochToDay(beginTime);
              const endDay = epochToDay(endTime);
              for (let item of this.itemList) {
                let url =
                  process.env.VUE_APP_BASE_URL +
                  `/api/itedu/v1/da/market-log/by-day?type=0&marketId=${item.id}&fromDay=${beginDay}&endDay=${endDay}`;
                item.url = url;
              }
            }
            this.totalPage = ret.totalPage;
            console.log("##", this.itemList, this.totalPage);
          } else {
            alertMessage(ret.msg, "warning");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onDeleteItem(index, row) {
      if (row.id < 100000) {
        msg.warn("id 100000 以下的不允许删");
        return;
      }
      this.$confirm("确定删除该项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          flexQRCodeApi.deleteItem(row.id).then(ret => {
            if (ret.code == 0) {
              alertMessage("删除成功", "success");
              this.onQuery();
            }
          });
        })
        .catch(() => {});
    },
    onEditItem(index, row) {
      this.$router.push({
        name: "qrcode-edit",
        params: { id: row.id }
      });
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.list-page {
  width: 100vw;
  .list-body {
    width: 100vw;
  }
}
</style>
